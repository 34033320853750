<template>
  <div class="orderHistory">
    <mt-header :title="$t('orderHistory')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <ul>
        <li v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/apply/orderDetails', query: { contractNo: item.applyId } })">
          <div class="wrap">
            <div class="title border-b">
              <span>{{ $t('contractNo') }}</span>
              <div>{{ item.applyId }}</div>
            </div>
            <div class="row border-b">
              <div class="name">{{ $t('amount') }}：</div>
              <div class="val">{{ item.applyAmount | formatMoney }}</div>
            </div>
            <div class="row border-b">
              <div class="name">{{ $t('applicationDate') }}：</div>
              <div class="val">{{ item.applyDate }}</div>
            </div>
            <div class="row border-b">
              <div class="name">{{ $t('loanTerm') }}：</div>
              <div class="val">{{ item.loanTerms }} {{ $t('day') }}</div>
            </div>
            <div class="row">
              <div class="name">{{ $t('contractStatus') }}：</div>
              <div class="val">{{ item.contractStatus }}</div>
            </div>
          </div>
          <img class="arrow" src="../../assets/arrow-r.png" height="15px">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { contractList } from '../../utils/api'
export default {
  name: 'OrderHistory',
  components: {
    callPhone
  },
  data() {
    return {
      loading: false,
      list: [],
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.loadMore()
      this.$indicator.close();
      this.$NProgress.done()
    },
    async loadMore() {
      this.loading = true;
      await this.$axios({
        method: 'post',
        url: contractList,
      })
        .then((e) => {
          if (e.status.code == '000') {
            for (let i = 1; i <= 10; i++) {
              this.list = e.body;
            }
            this.loading = false;
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.orderHistory {
  background-color: #FBFBFF;
  ul,li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  ul {
    flex-grow: 1;
    position: relative;
    li {
      display: block;
      padding: 10px 15px;
      text-decoration: none;
      border-top: 1px solid #EAECEC;
      border-bottom: 1px solid #EAECEC;
      margin-bottom: 30px;
      background: #fff;
      display: flex;
      align-items: center;
    }
    .wrap {
      flex: 1;
      border-radius: 10px;
    }
    .title {
      display: flex;
      align-items: center;
      height: 45px;
      color: #111111;
      font-size: 18px;
      img {
        width: 20px;
        margin: 0 15px;
      }
      div {
        color: #000;
        padding-left: 20px;
      }
    }
    .row {
      display: flex;
      height: 30px;
      color: #A8A8A8;
      font-size: 14px;
      font-weight: bold;
      align-items: center;
    }
  }
}
</style>